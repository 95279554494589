@import '../../../../scss/theme-bootstrap';

.sticky-footer-chat {
  #{$ldirection}: auto;
  #{$rdirection}: 20px;
  bottom: 135px;
  display: block;
  margin-top: 2px;
  max-width: 35px;
  position: fixed;
  width: auto;
  z-index: 100;
  @include breakpoint($landscape-up) {
    #{$rdirection}: 44px;
    margin-top: 20px;
  }
  .active-gnav & {
    position: relative;
    z-index: 1;
  }
  .sticky-footer-chat__link {
    #{$ldirection}: auto;
    #{$rdirection}: 40px;
    height: 35px;
    text-align: center;
    top: 75%;
    width: 35px;
    z-index: 100;

    img {
      background: $color-cream;
      border-radius: 100%;
      height: 100%;
    }
  }
}
